
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalMain {
    background: transparent;
    /* padding: 60px 20px; */
    border-radius: 8px;
    width: 80%;
    max-width: 850px;
    max-height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 10px;
  }
  
  .displayBlock {
    display: flex;
  }
  
  .displayNone {
    display: none;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    align-items: center;
  }




  @media (max-width: 991.98px) {
    .modalMain{
      width: 100%;
      height: 80vh;
    }
  }

.wrapper{
    display: flex;
    flex-direction: column;
}

.wrapper h2{
    font-size: 36px;
    font-weight: bolder;
    color: #000000;
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
}

.wrapper p{
    margin: 0;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
}

.wrapper p span{
    background: linear-gradient(to right, #FD7B33, #C41E3A);
    -webkit-background-clip: text;
    color: transparent;
}

.iconWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    height: 200px;
    justify-content: center;
}

.innerWrapper{
    background-color: whitesmoke;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.innerWrapper span:first-child{
    background-color: #ffffff;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.innerWrapper span:last-child{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.innerWrapper span svg{
    position: absolute;
}

.innerWrapper span svg{
    font-size: 36px;
}

.innerWrapper h4{
    font-size: 22px;
    margin: 0;
    margin-bottom: 5px;
    font-family: "Gelion Bold";
}

.innerWrapper p{
    font-size: 18px;
    margin: 0;
    font-family: "Gelion Regular";
}

.cards{
    margin-top: 20px;
}

.image{
    width: 100%;
    position: relative;
    flex: 0 632px;
}

.image img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media (max-width: 991.98px) {
    .wrapper h2{
        font-size: 26px;
        margin-bottom: 15px;
    }

    .wrapper p{
        font-size: 16px;
        margin-bottom: 30px;
    }

    .innerWrapper{
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 10px;
        border-radius: 8px;
    }

    .innerWrapper span:first-child{
        margin-right: 15px;
        width: 50px;
        height: 50px;
        margin-bottom: 0;
    }

    .innerWrapper span:last-child{
        text-align: left;
    }

    .iconWrapper{
        height: 100px;
        padding: 5px 0;
    }

    .innerWrapper span svg{
        font-size: 20px;
    }

    .innerWrapper h4{
        font-size: 20px;
    }

    .wrapper p{
        font-size: 16px;
    }

    .image{
        width: 100%;
        position: relative;
        flex: 0 1 213px;
    }
  }
  

  
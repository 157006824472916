.section{
    background-image: url('leads-bg.webp');
    background-size: cover;
    position: relative;
}

.section:after{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.833);
  left: 0;
  top: 0;
  content: '';
  position: absolute;
}

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 9;
}

.wrapper h2{
    font-size: 36px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 20px;
    font-family: "Gelion Black";
}

.wrapper h2 span{
     background: linear-gradient(to right, #FD7B33, #C41E3A);
    -webkit-background-clip: text;
    color: transparent;
}

.arrow {
    font-size: 2em;
    margin-top: 0px;
    margin-bottom: 25px;
    animation: bounce 2s infinite;
    color: orange;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }


  
@media (max-width: 991.98px) {
  .wrapper h2{
    font-size: 26px;
    text-align: center;
  }
}

.section{
    padding-bottom: 10px;
}

.wrapper{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
}

.wrapper .box{
    padding: 15px 5px;
    max-width: 165px;
    -webkit-box-shadow: 0 0 13px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 0 13px 5px rgba(0, 0, 0, .05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
}

.wrapper .box span{
    padding: 5px 0;
}

.wrapper .box span:nth-child(1){
    text-transform: uppercase;
    font-size: 18px;
    color: #a7a7a7;
}

.wrapper .box span:nth-child(2){
    text-transform: uppercase;
    color: #ffb74d;
    font-weight: 700;
    font-size: 55px;
    line-height: 1.3;
}

.wrapper .box span:nth-child(3) img{
    height: 30px;
}






@media (max-width: 991.98px) {
    .wrapper{ 
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 5px;
        flex-wrap: wrap;
        max-width: 440px;
        box-sizing: border-box;
      }
      
      .box {
        flex: 1 1 calc(50% - 20px);
        box-sizing: border-box;
      }
  }
  
 .section{
    padding-top: 0;
 }

    .wrapper{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .wrapper h2{
    font-size: 36px;
    font-weight: bolder;
    color: #000000;
    margin: 0;
    margin-bottom: 15px;
    flex: 1;
}

.wrapper p{
  margin: 0;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.wrapper p span{
  background: linear-gradient(to right, #FD7B33, #C41E3A);
  -webkit-background-clip: text;
  color: transparent;
}

/* .wrapper span{
    display: flex;
    justify-content: flex-end;
} */
  
  .slider {
  }
  
  .testimonialWrapper {
    background-color: #ffffff;
    border: 1px solid #daebfd;
    height: 100%;
    border-radius: 10px;

  }
  
  .testimonialWrapper .testimonial {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  }
  
  .testimonialCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .testimonialCard .image {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .testimonialCard .name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .testimonialCard .review {
    font-size: 14px;
    color: #777;
  }
  

  @media (max-width: 991.98px) {
    .wrapper h2{
      font-size: 26px;
      margin-bottom: 15px;
  }

    .wrapper p{
        font-size: 16px;
        margin-bottom: 30px;
    }
  }

.testimonialCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.1);  */
    /* margin: 0 10px; */
  }
  
  .image {
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px 10px 0 0;
    position: relative;
  }

  .image .badge{
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 3px 12px;
    font-size: 11px;
    font-family: "Gelion Medium";
  }
  
  .desc {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
  }
  
  .name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
  }

  .name .text{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .name span:first-child{
    background-color: #777;
    color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 5px;
    line-height: 0;
  }

  .name span:nth-child(2){

  }
  
  .review {
    font-size: 14px;
    color: #777;
    text-align: left;
    padding: 0 20px 20px;
  }

  .review p{
    margin: 0;
    font-size: 16px;
  }
  

  .stars {
    display: flex;
    margin: 0.5em 0;
    color: aqua;
  }
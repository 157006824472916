/* src/components/LeadCapture.module.css */

.leadCapture {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70.66%; /* Maintain a 16:9 aspect ratio */
  overflow: hidden;
}

.leadCapture iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; /* Ensure no border */
}

/* Media Queries for Different Screen Sizes */

/* Extra Small Devices (Portrait Phones) */
@media (max-width: 991.98px) {
  .leadCapture {
    display: flex;
    flex: 100vh 1;
    overflow: hidden;
  }
}

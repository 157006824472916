footer{
    background-color: #000000;
}

.wrapper{
    display: flex;
    flex-direction: column;
}

.wrapper p{
    color: #ffffff;
}

.wrapper ul{
    display: flex;
    flex-direction: row;
}

.wrapper ul li{
    padding: 0 30px;
    position: relative;
}

.wrapper ul li:after{
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #ffffff;
    content: '';
}

.wrapper ul li:last-child:after{
    display: none;
}

.wrapper ul li a{
    color: #ffffff;
    text-decoration: none;
}

.wrapper ul{
    align-self: flex-end;
}

/* Contact Form styles */
.contactForm {
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
    box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
    border-radius: 8px;
  }
  
  .contactForm h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .contactForm .formGroup {
    margin-bottom: 15px;
  }
  
  .contactForm .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .contactForm .formGroup input,
  .contactForm .formGroup textarea {
    width: 100%;
    padding: 10px 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
  }
  
  .contactForm .formGroup input:focus,
  .contactForm .formGroup textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .contactForm .formGroup textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contactForm .formGroup button {
    display: inline-block;
    padding: 10px 20px;
    color: rgb(255, 255, 255) !important;
    background: linear-gradient(to right, rgb(253, 123, 51), rgb(196, 30, 58));
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .contactForm .formGroup button:hover {
    background-color: #0056b3;
  }

  .consent{
    display: flex !important;
    font-size: 12px !important;
  }

  .consent input{
    width: auto !important;
  }

  .row {
    display: flex;
    gap: 1rem; /* Add spacing between the columns */
  }
  
  .formGroup {
    flex: 1;
  }

  .privacy{
    background-color: #ffffff;
    padding: 10px;
    overflow: auto;
    height: 100vh;
  }

  @media (max-width: 991.98px) {
    .wrapper{
      align-items: center;
    }

    .wrapper ul{
      align-self: center;
      padding: 0;
    }

    .wrapper ul li{
      padding: 0 20px;
  }
  }

  
  


  .notification {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, rgb(253, 123, 51), rgb(196, 30, 58));
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideDown 0.5s ease-in-out, fadeOut 0.5s ease-in-out 2.5s forwards;
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
      transform: translateX(-50%) translateY(-10px);
    }
  }
  
@font-face {
  font-family: "Gelion Bold";
  src: url("fonts/Gelion-Bold/Web-Fonts/3e55fc4470728e51c056db1b010924f2.eot");
  src: url("fonts/Gelion-Bold/Web-Fonts/3e55fc4470728e51c056db1b010924f2.eot?#iefix")format("embedded-opentype"),
  url("fonts/Gelion-Bold/Web-Fonts/3e55fc4470728e51c056db1b010924f2.woff2")format("woff2"),
  url("fonts/Gelion-Bold/Web-Fonts/3e55fc4470728e51c056db1b010924f2.woff")format("woff"),
  url("fonts/Gelion-Bold/Web-Fonts/3e55fc4470728e51c056db1b010924f2.ttf")format("truetype"),
  url("fonts/Gelion-Bold/Web-Fonts/3e55fc4470728e51c056db1b010924f2.svg#Gelion-Bold")format("svg");
}


@font-face {
  font-family: "Gelion Black";
  src: url("fonts/Gelion-Black/Web-Fonts/2ec02d021d37a2e089e73f9d01163c9f.eot");
  src: url("fonts/Gelion-Black/Web-Fonts/2ec02d021d37a2e089e73f9d01163c9f.eot?#iefix")format("embedded-opentype"),
  url("fonts/Gelion-Black/Web-Fonts/2ec02d021d37a2e089e73f9d01163c9f.woff2")format("woff2"),
  url("fonts/Gelion-Black/Web-Fonts/2ec02d021d37a2e089e73f9d01163c9f.woff")format("woff"),
  url("fonts/Gelion-Black/Web-Fonts/2ec02d021d37a2e089e73f9d01163c9f.ttf")format("truetype"),
  url("fonts/Gelion-Black/Web-Fonts/2ec02d021d37a2e089e73f9d01163c9f.svg#Gelion Black")format("svg");
}


@font-face {
  font-family: "Gelion Medium";
  src: url("fonts/Gelion-Medium/Web-Fonts/9c94fb93725237bc6bdb29dbe2622a18.eot");
  src: url("fonts/Gelion-Medium/Web-Fonts/9c94fb93725237bc6bdb29dbe2622a18.eot?#iefix")format("embedded-opentype"),
  url("fonts/Gelion-Medium/Web-Fonts/9c94fb93725237bc6bdb29dbe2622a18.woff2")format("woff2"),
  url("fonts/Gelion-Medium/Web-Fonts/9c94fb93725237bc6bdb29dbe2622a18.woff")format("woff"),
  url("fonts/Gelion-Medium/Web-Fonts/9c94fb93725237bc6bdb29dbe2622a18.ttf")format("truetype"),
  url("fonts/Gelion-Medium/Web-Fonts/9c94fb93725237bc6bdb29dbe2622a18.svg#Gelion Medium")format("svg");
}


@font-face {
  font-family: "Gelion Semi Bold";
  src: url("fonts/Gelion-Semi-Bold/Web-Fonts/288480b704b52173b642af77e54fc366.eot");
  src: url("fonts/Gelion-Semi-Bold/Web-Fonts/288480b704b52173b642af77e54fc366.eot?#iefix")format("embedded-opentype"),
  url("fonts/Gelion-Semi-Bold/Web-Fonts/288480b704b52173b642af77e54fc366.woff2")format("woff2"),
  url("fonts/Gelion-Semi-Bold/Web-Fonts/288480b704b52173b642af77e54fc366.woff")format("woff"),
  url("fonts/Gelion-Semi-Bold/Web-Fonts/288480b704b52173b642af77e54fc366.ttf")format("truetype"),
  url("fonts/Gelion-Semi-Bold/Web-Fonts/288480b704b52173b642af77e54fc366.svg#Gelion Semi Bold")format("svg");
}


@font-face {
  font-family: "Gelion Regular";
  src: url("fonts/Gelion-Regular/Web-Fonts/f897f2445a35e3c60692d2c949fca465.eot");
  src: url("fonts/Gelion-Regular/Web-Fonts/f897f2445a35e3c60692d2c949fca465.eot?#iefix")format("embedded-opentype"),
  url("fonts/Gelion-Regular/Web-Fonts/f897f2445a35e3c60692d2c949fca465.woff2")format("woff2"),
  url("fonts/Gelion-Regular/Web-Fonts/f897f2445a35e3c60692d2c949fca465.woff")format("woff"),
  url("fonts/Gelion-Regular/Web-Fonts/f897f2445a35e3c60692d2c949fca465.ttf")format("truetype"),
  url("fonts/Gelion-Regular/Web-Fonts/f897f2445a35e3c60692d2c949fca465.svg#Gelion Regular")format("svg");
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  letter-spacing: -0.5px;
}

h1,h2,h3,h4,h5,h6{
  font-family: "Gelion Bold";
}

p, ul, ol{
  font-family: "Gelion Regular";
}


.section, header {
  padding: 50px 0;
}


.col-12 { flex: 0 0 100%; }


.slick-list {margin: 0 -10px;}
.slick-dots{bottom: -50px !important;}
.slick-slide>div {padding: 0 20px; padding-left: 0;}




* {
  box-sizing: border-box;
}




/* Container */
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1140px;
}

/* Row */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* Columns */
.col {
  padding: 0 15px;
  box-sizing: border-box;
  flex: 1 0 0%; /* Include padding in the width */
}

/* Extra small devices (phones, less than 576px) */
.col-xs-1 { flex: 0 0 8.33%; max-width: 8.33%; }
.col-xs-2 { flex: 0 0 16.67%; max-width: 16.67%; }
.col-xs-3 { flex: 0 0 25%; max-width: 25%; }
.col-xs-4 { flex: 0 0 33.33%; max-width: 33.33%; }
.col-xs-5 { flex: 0 0 41.67%; max-width: 41.67%; }
.col-xs-6 { flex: 0 0 50%; max-width: 50%; }
.col-xs-7 { flex: 0 0 58.33%; max-width: 58.33%; }
.col-xs-8 { flex: 0 0 66.67%; max-width: 66.67%; }
.col-xs-9 { flex: 0 0 75%; max-width: 75%; }
.col-xs-10 { flex: 0 0 83.33%; max-width: 83.33%; }
.col-xs-11 { flex: 0 0 91.67%; max-width: 91.67%; }
.col-xs-12 { flex: 0 0 100%; max-width: 100%; }

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) {
  .col-sm-1 { flex: 0 0 8.33%; max-width: 8.33%; }
  .col-sm-2 { flex: 0 0 16.67%; max-width: 16.67%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33%; max-width: 33.33%; }
  .col-sm-5 { flex: 0 0 41.67%; max-width: 41.67%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.33%; max-width: 58.33%; }
  .col-sm-8 { flex: 0 0 66.67%; max-width: 66.67%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.33%; max-width: 83.33%; }
  .col-sm-11 { flex: 0 0 91.67%; max-width: 91.67%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) {
  .col-md-1 { flex: 0 0 8.33%; max-width: 8.33%; }
  .col-md-2 { flex: 0 0 16.67%; max-width: 16.67%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33%; max-width: 33.33%; }
  .col-md-5 { flex: 0 0 41.67%; max-width: 41.67%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33%; max-width: 58.33%; }
  .col-md-8 { flex: 0 0 66.67%; max-width: 66.67%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33%; max-width: 83.33%; }
  .col-md-11 { flex: 0 0 91.67%; max-width: 91.67%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
}

/* Large devices (larger desktops, 992px and up) */
@media (min-width: 992px) {
  .col-lg-1 { flex: 0 0 8.33%; max-width: 8.33%; }
  .col-lg-2 { flex: 0 0 16.67%; max-width: 16.67%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.33%; max-width: 33.33%; }
  .col-lg-5 { flex: 0 0 41.67%; max-width: 41.67%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.33%; max-width: 58.33%; }
  .col-lg-8 { flex: 0 0 66.67%; max-width: 66.67%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.33%; max-width: 83.33%; }
  .col-lg-11 { flex: 0 0 91.67%; max-width: 91.67%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col-xl-1 { flex: 0 0 8.33%; max-width: 8.33%; }
  .col-xl-2 { flex: 0 0 16.67%; max-width: 16.67%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.33%; max-width: 33.33%; }
  .col-xl-5 { flex: 0 0 41.67%; max-width: 41.67%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.33%; max-width: 58.33%; }
  .col-xl-8 { flex: 0 0 66.67%; max-width: 66.67%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.33%; max-width: 83.33%; }
  .col-xl-11 { flex: 0 0 91.67%; max-width: 91.67%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
}





@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}







@media (max-width: 575.98px) {
  .slick-arrow{
    display: none !important;
  }

  .slick-slide>div{
    padding: 0;
  }

  
}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) and (max-width: 1399.98px) {

}

@media (min-width: 1400px) {

}


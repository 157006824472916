
.box {
    padding: 15px 5px;
    max-width: 165px;
    -webkit-box-shadow: 0 0 13px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 0 13px 5px rgba(0, 0, 0, .05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
}

.box span {
    padding: 5px 0;
}

.box span:nth-child(1) {
    text-transform: uppercase;
    font-size: 18px;
    color: #a7a7a7;
}

.box span:nth-child(2) {
    text-transform: uppercase;
    color: #ffb74d;
    font-weight: 700;
    font-size: 55px;
    line-height: 1.3;
}

.box span:nth-child(3) img {
    height: 30px;
}

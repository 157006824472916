.section{
    padding: 0;
    background-image: url('maximize_bg.webp');
    background-repeat: no-repeat;
    background-size: contain; 
    background-position: right;
}

.wrapper{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.wrapper h3{
    font-size: 36px;
    font-weight: bolder;
    color: #000000;
    margin: 0;
    text-align: center;
    text-align: left;
}

.wrapper .text{
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
}

.wrapper .logo{
    width: 80px;
    margin-bottom: 10px;
}

.wrapper h4{
    font-size: 18px;
    color: orange;
    position: relative;
    padding-bottom: 5px;
    cursor:default;
}



.wrapper h4::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #000000;
    z-index: 99999;
    transition: width 2s;
}

.wrapper h4:hover::after {
    width: 100%;
}

.wrapper h4:not(:hover)::after {
    width: 0;
}

.wrapper .image img{
    position: relative;
    bottom: -5px;
}


@media (max-width: 991.98px) {
    .section{
      padding: 50px 0 0;
      background-size: cover;
    }
    .wrapper{
      flex-direction: column;
    }

    .wrapper .text{
      width: 100%;
    }

    .wrapper .image img{
      height: 300px;
      margin: 0 auto;
      bottom: 0;
    }

    .wrapper .image{
      display: flex;
      align-items: center;
    }
  }
  

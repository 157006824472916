.wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.wrapper h2{
    font-size: 36px;
    font-weight: bolder;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
}

@media (max-width: 991.98px) {
    .wrapper h2{
      font-size: 26px;
      margin-bottom: 15px;
  }

  }
  

